<template>
  <div :class="['ticket-item', `ticket-item--${view}`, { 'ticket-item--approval': isApproval }]">
    <div
      class="ticket-item__hat"
      :style="{ backgroundColor: status.color }"
    />

    <div class="ticket-item__cnt">
      <div class="ticket-item__body">
        <div class="ticket-item__head-block">
          <div class="ticket-item__head">
            <esmp-link :to="detailPage" class="ticket-item__link" />
            <div
              v-if="priority"
              class="ticket-item__priority"
              :style="{'color': ticketPriorityColor }"
            >
              <esmp-icon :name="`priority-${priority.tag}`" />
            </div>
            <div class="ticket-item__title">
              {{ ticket.service }}
            </div>
          </div>
          <div v-if="isLine" class="ticket-item__info">
            <div class="ticket-item__info-item">
              <div class="ticket-item__info-item__value">
                {{ ticket.number }}
              </div>
            </div>
            <div class="ticket-item__info-item">
              <div class="ticket-item__info-item__name">
                {{ ticket.createdAt | dateFormat('DD.MM.YYYY, HH:mm') }}
              </div>
            </div>
          </div>

          <div
            v-if="isApproval"
            :class="['ticket-item__select', { 'ticket-item__select--selected': isSelected }]"
            title="Выделить"
            @click="selectHandler"
          />
        </div>

        <div v-if="isLine" class="ticket-item__responsible-block">
          <div v-if="responsibleName" class="ticket-item__responsible">
            {{ responsibleName }}
          </div>
        </div>

        <div class="ticket-item__status-block">
          <esmp-tag
            is-status
            :color="status.color"
            size="small"
          >
            {{ status.name }}
          </esmp-tag>

          <div v-if="!isLine && responsibleName" class="ticket-item__responsible">
            {{ responsibleName }}
          </div>
        </div>

        <div class="ticket-item__description-block">
          <div class="ticket-item__description">
            <div class="ticket-item__description-title">
              {{ ticket.title }}
            </div>
            <div class="ticket-item__description-text" v-html="getCutDescription()" />
          </div>
          <div v-if="!isLine && ticket.isMassFail" class="ticket-item__massive-fail">
            <esmp-icon v-tooltip="{value: 'Массовый сбой'}" name="info2" />
          </div>
        </div>

        <div class="ticket-item__info ticket-item__info-block">
          <div v-if="isLine && ticket.isMassFail" class="ticket-item__massive-fail">
            Массовый сбой
            <esmp-icon name="info2" />
          </div>
          <div v-if="!isLine" class="ticket-item__info-item">
            <div class="ticket-item__info-item__name">
              <esmp-icon name="document2" />
              Номер
            </div>
            <div class="ticket-item__info-item__value">
              {{ ticket.number }}
            </div>
          </div>

          <div v-if="!isLine" class="ticket-item__info-item">
            <div class="ticket-item__info-item__name">
              <esmp-icon name="calendar2" />
              Создана
            </div>
            <div class="ticket-item__info-item__value">
              {{ ticket.createdAt | dateFormat('DD.MM.YYYY, HH:mm') }}
            </div>
          </div>

          <div v-if="ticket.solutionDestinationTime" class="ticket-item__info-item">
            <div class="ticket-item__info-item__name">
              <esmp-icon v-if="!isLine" name="clock2" />
              Cрок до
            </div>
            <div class="ticket-item__info-item__value">
              {{ ticket.solutionDestinationTime | dateFormat('DD.MM.YYYY, HH:mm') }}
            </div>
          </div>

          <div v-if="ticket.rating" class="ticket-item__info-item">
            <div v-if="!isLine" class="ticket-item__info-item__name">
              <esmp-icon name="star2" />
              Оценка
            </div>
            <div class="ticket-item__info-item__value">
              <esmp-rate
                disabled
                v-model="rating"
              />
            </div>
          </div>

          <div
            v-if="ticket.customer"
            class="ticket-item__info-item"
          >
            <div class="ticket-item__info-item__name">
              <esmp-icon v-if="!isLine" name="user-man" />

              Клиент
            </div>
            <div
              class="ticket-item__info-item__value ticket-item__info-item__show-customer-info-button"
              :title="`Информация о пользователе ${ticket.customer.fullName}`"
              @click="getCustomerInfo(ticket.customer.email)"
            >
              <esmp-user-avatar
                :username="ticket.customer.fullName"
                class="ticket-item__info-item-avatar"
              />

              <span>{{ ticket.customer.fullName }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="ticket-item__actions">
        <div
          v-if="showRateButton"
          class="ticket-item__action ticket-item__action--icon"
          @click="showModals.ModalTicketRate = true"
        >
          <esmp-icon name="star" class="ticket-item__action-icon" />
          Оценить
        </div>
        <div
          v-if="showReopenButton"
          class="ticket-item__action ticket-item__action--gray"
          @click="showModals.ModalTicketReopen = true"
        >
          Вернуть в работу
        </div>
        <div
          v-if="showCancelButton"
          class="ticket-item__action"
          @click="showModals.ModalTicketCancel = true"
        >
          Отменить
        </div>
        <div
          v-if="showMessageButton"
          class="ticket-item__action"
          @click="showModals.ModalTicketComment = true"
        >
          Сообщение
        </div>
        <div
          v-if="showApprovalButtons"
          class="ticket-item__action"
          @click="showModalTicketApproval(true)"
        >
          Согласовать
        </div>
        <div
          v-if="showApprovalButtons"
          class="ticket-item__action"
          @click="showModalTicketApproval(false)"
        >
          Отклонить
        </div>
        <div
          v-if="showDetailButton"
          class="ticket-item__action"
        >
          <esmp-link :to="detailPage" class="ticket-item__link" />
          Детали заявки
        </div>
      </div>
    </div>

    <modal-ticket-comment
      :is-show="showModals.ModalTicketComment"
      :source-system="ticket.sourceSystem"
      :ticket-id="ticket.id"
      @hide-modal="hideModal"
    />

    <modal-ticket-cancel
      :is-show="showModals.ModalTicketCancel"
      :source-system="ticket.sourceSystem"
      :ticket-id="ticket.id"
      @hide-modal="hideModal"
      @fetch-tickets="$emit('fetch-tickets')"
    />

    <modal-ticket-reopen
      :is-show="showModals.ModalTicketReopen"
      :source-system="ticket.sourceSystem"
      :ticket-id="ticket.id"
      @hide-modal="hideModal"
      @fetch-tickets="$emit('fetch-tickets')"
    />

    <modal-ticket-rate
      :is-show="showModals.ModalTicketRate"
      :source-system="ticket.sourceSystem"
      :ticket-id="ticket.id"
      @hide-modal="hideModal"
      @fetch-tickets="$emit('fetch-tickets')"
    />

    <modal-ticket-approval
      :is-show="showModals.ModalTicketApproval"
      :is-approve="isApprove"
      :tickets="[ticket]"
      @hide-modal="hideModal"
      @fetch-tickets="$emit('fetch-tickets')"
    />

    <modal-user-info
      v-if="customerInfo"
      :is-show="showModals.ModalUserInfo"
      :current-user-info="customerInfo"
      @hide-modal="hideModal"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ModalTicketComment from '@/components/modals/modal-ticket-comment';
import ModalTicketCancel from '@/components/modals/modal-ticket-cancel';
import ModalTicketReopen from '@/components/modals/modal-ticket-reopen';
import ModalTicketRate from '@/components/modals/modal-ticket-rate';
import ModalTicketApproval from '@/components/modals/modal-ticket-approval';
import ModalUserInfo from '@/components/modals/modal-user-info';
import cutWithBr from '@/utils/cutWithBr';
import { STATUS_TYPES, TICKET_DESCRIPTION_MAX_LENGTH, VIEW_TYPES } from '@/constants/ticket';
import PRIORITY_COLORS_MAP from '@/pages/Ticket/helpers/priority.colors';
import { getStatus } from '@/helpers/status';

export default {
  name: 'TicketItem',
  components: {
    ModalUserInfo,
    ModalTicketComment,
    ModalTicketCancel,
    ModalTicketReopen,
    ModalTicketRate,
    ModalTicketApproval,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    view: {
      type: String,
      default: VIEW_TYPES[0],
      validator(value) {
        return VIEW_TYPES.includes(value);
      },
    },
    isApproval: {
      type: Boolean,
      default: false,
    },
    selectedTicketIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModals: {
        ModalTicketComment: false,
        ModalTicketCancel: false,
        ModalTicketReopen: false,
        ModalTicketRate: false,
        ModalTicketApproval: false,
        ModalUserInfo: false,
      },
      customerInfo: null,
      isApprove: true,
    };
  },
  computed: {
    status() {
      return getStatus(this.ticket.state);
    },
    priority() {
      if (!this.ticket.priority || !this.ticket.priority.tag) return '';
      return this.ticket.priority;
    },
    ticketPriorityColor() {
      if (!this.priority) return null;
      return this.priority.color || PRIORITY_COLORS_MAP[this.priority.tag];
    },
    responsibleName() {
      return this.ticket
        && this.ticket.responsible
        && this.ticket.responsible.fullName
        ? this.ticket.responsible.fullName
        : null;
    },
    rating() {
      return Number(this.ticket.rating);
    },

    isLine() {
      return this.view === 'line';
    },
    isSelected() {
      return this.selectedTicketIds.some((id) => id === this.ticket.id);
    },

    showMessageButton() {
      return !this.ticket.commentAction.isHidden;
    },
    showCancelButton() {
      // if (this.ticket.service === CUSTOM_SERVICES.businessTrip.serviceName) {
      //   return false;
      // }

      return this.ticket.showCancelButton;
    },
    showReopenButton() {
      return this.ticket.showReopenButton;
    },
    // TODO: переместить на бек (ESMPCP-1839)
    showRateButton() {
      // if (this.ticket.service === CUSTOM_SERVICES.businessTrip.serviceName) {
      //   return false;
      // }
      return (
        this.status?.code === STATUS_TYPES.resolved.id
        || this.status?.code === STATUS_TYPES.executed.id);
    },
    showApprovalButtons() {
      return this.ticket.showApprovalButtons;
    },
    showDetailButton() {
      return !(this.showMessageButton
        || this.showCancelButton
        || this.showReopenButton
        || this.showRateButton
        || this.showApprovalButtons);
    },
    detailPage() {
      if (this.isApproval) {
        return `/myagree/approval/${this.ticket.sourceSystem}/${this.ticket.id}`;
      }
      return `/tickets/view-ticket/${this.ticket.sourceSystem}/${this.ticket.id}`;
    },
  },
  methods: {
    ...mapActions('ticket', ['fetchUserAdditionalInfo']),

    hideModal(payload) {
      if (this.showModals[payload.modalName]) {
        this.showModals[payload.modalName] = payload.newValue;
      }
    },
    showModalTicketApproval(isApprove) {
      this.isApprove = isApprove;
      this.showModals.ModalTicketApproval = true;
    },

    async getCustomerInfo(userLogin) {
      // данные о юзере загружаются только 1 раз
      if (!this.customerInfo) {
        this.customerInfo = await this.fetchUserAdditionalInfo(userLogin);
      }

      if (this.customerInfo) {
        this.showModals.ModalUserInfo = true;
      }
    },

    getCutDescription() {
      if (
        this.ticket.description
        && this.ticket.description.length > TICKET_DESCRIPTION_MAX_LENGTH
      ) {
        return cutWithBr(
          this.ticket.description,
          TICKET_DESCRIPTION_MAX_LENGTH,
        );
      }
      return this.ticket.description;
    },
    selectHandler() {
      if (this.isSelected) {
        const updArr = this.selectedTicketIds.filter((id) => id !== this.ticket.id);
        this.$emit('update:selectedTicketIds', updArr);
      } else {
        const updArr = [this.ticket.id, ...this.selectedTicketIds];
        this.$emit('update:selectedTicketIds', updArr);
      }
    },
  },
};
</script>

<style lang="scss">
$gap-h: 20px;
$gap-v: 32px;
$right-column-width: 335px;
$line-view-pad-r: 26px;

.ticket-item {
  $base-class: &;

  position: relative;
  margin-top: $gap-v;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 290px;
    margin-left: $gap-h;
  }

  &__cnt {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: $color-white;
    z-index: 2;
  }
  &__hat {
    position: absolute;
    top: -8px;
    left: 0;
    width: 96px;
    height: 24px;
    border-radius: 8px;
    z-index: 1;
  }

  &__body {
    flex-grow: 1;
    border-radius: 16px;
    background-color: $color-white;
    padding: 20px;
    box-shadow: 0 0 1px rgba(28, 41, 61, 0.1), 0 36px 36px rgba(28, 41, 61, 0.04);
  }
  &__head-block {
    margin-bottom: 12px;
  }
  &__head {
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__priority {
    display: inline-block;
    margin-right: 4px;
    .esmp-svg-icon {
      width: 12px;
      height: 12px;
    }
  }
  &__title {
    display: inline;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
  &__status-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  &__responsible {
    font-size: 10px;
    line-height: 16px;
    color: #797E8B;
  }
  &__description-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__description {
    margin-bottom: 16px;
    word-break: break-word;
  }
  &__description-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  &__description-text {
    font-size: 12px;
    line-height: 16px;
    color: rgba(16, 24, 40, 0.7);
  }
  &__massive-fail {
    display: flex;
    color: $color-error-night;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &__info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    &__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #797E8B;
      margin-right: $base-gutter;
      .esmp-svg-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
    &__value {
      .esmp-svg-icon {
        width: 16px;
        height: 16px;
      }
      .esmp-rate-star:last-child {
        margin-right: 0;
      }
    }

    &__show-customer-info-button {
      text-align: end;
      cursor: pointer;

      & > span {
        width: calc(100% - 16px);
        text-overflow: ellipsis;
        word-break: break-word;
        overflow: hidden;
      }
    }
  }
  &__info-item + &__info-item {
    margin-top: 8px;
  }

  &__select {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid $color-client-portal-logo;
    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      background-color: $color-client-portal-logo;
      border-radius: 50%;
      opacity: 0;
      transition: opacity $base-animation-time;
    }
    &--selected::before {
      opacity: 1;
    }
    &:hover {
      cursor:  pointer;
      &::before {
        opacity: 0.45;
      }
    }
  }

  &__actions {
    flex-grow: 0;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    color: $color-black;
    transition: color $base-animation-time;
    width: 50%;
    position: relative;
    &--gray {
      color: rgba(16, 24, 40, 0.5);
    }
    &-icon {
      color: #FFBE08;
      display: inline-block;
      margin-right: 4px;
      margin-top: -6px;
      &.esmp-svg-icon {
        width: 15px;
        height: 15px;
      }
    }

    &:first-child:last-child {
      width: 100%;
    }
    &:nth-child(2) {
      border-left: 1px solid rgba(16, 24, 40, 0.1);
    }
    &:hover {
      color: $color-client-portal-logo;
      cursor: pointer;
    }
  }

  &__info-item-avatar {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: -4px;
    display: inline-block;
  }

  &--approval {
  }
  /** view card **/
  &--card {
    min-height: 370px;

    #{$base-class}__body {
      display: flex;
      flex-direction: column;
    }
    #{$base-class}__info-block {
      margin-top: auto;
      min-height: 64px;
    }
    &#{$base-class}--approval {
      #{$base-class}__head {
        padding-right: 30px;
      }
      #{$base-class}__head-block {
        position: relative;
      }
    }
  }
  /** view line **/
  &--line {
    width: 100%;
    margin-top: 8px;

    #{$base-class}__cnt {
      flex-direction: row;
    }
    #{$base-class}__hat {
      top: 0;
      left: -8px;
      width: 24px;
      height: 76px;
    }
    #{$base-class}__body {
      width: 82%;
      flex-grow: 1;
      display: flex;
    }
    #{$base-class}__select {
      top: calc(50% - 11px);
      left: 10px;
    }

    #{$base-class}__head-block {
      width: 32%;
      order: 0;
      padding-right: $line-view-pad-r;
      margin-bottom: 0;
      padding-left: 20px;
    }
    #{$base-class}__priority {
      margin-left: -20px;
    }
    #{$base-class}__title {
      font-size: 14px;
      line-height: 20px;
    }
    #{$base-class}__info-item {
      margin-top: 6px;
    }

    #{$base-class}__description-block {
      width: 24%;
      order: 1;
      padding-right: $line-view-pad-r;
      margin-bottom: 0;
    }
    #{$base-class}__description-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      box-orient: vertical;
    }
    #{$base-class}__responsible-block {
      width: 12%;
      order: 2;
      padding-right: 20px;
      align-self: center;
    }

    #{$base-class}__info-block {
      width: 14%;
      order: 3;
      padding-right: 16px;
      align-self: center;
      #{$base-class}__info-item {
        display: block;
        text-align: right;
        &__name {
          justify-content: flex-end;
          color: $color-black;
          .esmp-svg-icon {
            display: none;
          }
        }
        &__value {
          .esmp-svg-icon {
            width: 16px;
            height: 16px;
          }
          .esmp-rate {
            display: flex;
            justify-content: right;
          }
          .esmp-rate-star {
            margin-right: math.div($base-gutter, 2);
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    #{$base-class}__status-block {
      width: 18%;
      order: 4;
      margin-bottom: 0;
      justify-content: flex-end;
    }

    #{$base-class}__actions {
      flex-grow: 1;
      width: 18%;
    }
  }
}
</style>
