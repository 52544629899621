<template>
  <div class="modal-ticket-rate">
    <esmp-modal
      v-model="show"
      title="Оцените выполнение заявки"
      :width="800"
      class-name="modal-ticket-rate"
    >
      <esmp-rate
        class="modal-ticket-rate__rate"
        v-model="rate"
      />
      <hm-text-area
        placeholder="Ваш комментарий"
        v-model="comment"
        :required="rate && rate < 4"
      />
      <div slot="footer">
        <esmp-button @click="rateHandler">
          Отправить
        </esmp-button>
        <esmp-button view="outline" @click="close">
          Закрыть
        </esmp-button>
      </div>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import HmTextArea from '@/components/hm-form/controls/HmTextArea.vue';

export default {
  name: 'ModalTicketRate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceSystem: {
      type: String,
      required: true,
    },
    ticketId: {
      type: String,
      required: true,
    },
  },
  components: {
    HmTextArea,
  },
  data() {
    return {
      rate: null,
      comment: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    async rateHandler() {
      if (this.rate > 3 || (this.rate < 4 && !!this.comment.length)) {
        this.setLoading({ key: 'app', value: true });

        this.$API.ticket.rateTicket(
          this.ticketId,
          {
            rating: this.rate,
            comment: this.comment,
          },
          { source: this.sourceSystem },
        )
          .then(() => {
            this.rate = null;
            this.comment = '';
            this.$EsmpNotify.$show('Заявка оценена', 'success');

            this.$emit('fetch-tickets');
          })
          .catch((err) => err);
        this.setLoading({ key: 'app', value: false });
        this.close();
      } else if (!this.rate) {
        this.$EsmpNotify.$show('Проставьте оценку', 'error');
      } else if (this.rate < 4 && !this.comment.length) {
        this.$EsmpNotify.$show('Заполните комментарий', 'error');
      }
    },
    close() {
      this.show = false;
    },
  },
  watch: {
    show() {
      this.rate = null;
      this.comment = '';
    },
  },
};
</script>

<style lang="scss">
.esmp-modal-body {
  font-size: 16px;
}
.modal-ticket-rate__rate {
  margin-bottom: 24px;
  .esmp-svg-icon {
    width: 60px;
    height: 60px;
  }
}
</style>
