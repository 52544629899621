const brRegExp = /<(b|B)(r|R)\s?\/?>/g;
const maxBrLength = 6;

export default (str, length) => {
  if (str.length <= length) return str;

  const checkeCutLength = length - maxBrLength;
  let cutLength = +length;

  const checkedStr = str.substr(checkeCutLength);
  const startBrInd = checkedStr.search(brRegExp);
  if (startBrInd !== -1) {
    let endBrInd = checkedStr.substr(startBrInd).indexOf('>');
    endBrInd += startBrInd + checkeCutLength;
    if (endBrInd >= length) {
      cutLength = startBrInd + checkeCutLength - 1;
    }
  }

  return str.slice(0, cutLength);
};
